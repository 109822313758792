import React from "react"
import SngrHeader from "./SngrHeader/SngrHeader"
import "normalize.css"

export default function Layout({ children }) {
  return (
    <>
      <SngrHeader />
      <main>
        {children}
      </main>
    </>
  )
}
